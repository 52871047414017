<template>
  <div>
    <div class="mt-1" data-type="data">
      <validation-observer ref="observer" v-slot="{ invalid, handleSubmit }">
        <b-form @submit.prevent="">
          <b-card header="Dati Identificativi" header-tag="header">
            <b-card-text>
              <b-overlay center :show="isLoading" rounded="sm">
                <div v-if="!isLoading">
                  <div v-if="ok">
                    <b-row>
                      <div class="form-group col-md-3">
                        <base-input
                          :name="beForm[rep].code.label"
                          vid="code"
                          :label="beForm[rep].code.label"
                          v-model="form[rep].code"
                          placeholder="Inserisci un codice gruppo"
                          :rules="getRules('code')"
                        />
                      </div>
                      <div class="form-group col-md-3">
                        <base-input
                          :name="beForm[rep].title.label"
                          vid="title"
                          :label="beForm[rep].title.label"
                          v-model="form[rep].title"
                          placeholder="Inserisci una descrizione"
                          :rules="getRules('title')"
                        />
                      </div>
                      <div class="form-group col-md-3 align-self-end">
                        <b-button
                          @click="handleSubmit(onSubmit)"
                          type="button"
                          variant="lisaweb"
                          size="sm"
                          :disabled="invalid"
                          title="Salva dati identificativi"
                        >
                          Salva
                        </b-button>
                      </div>
                    </b-row>
                  </div>
                  <div v-else>
                    {{ errorMessage }}
                  </div>
                </div>
                <template #overlay>
                  <div class="text-center mt-5">
                    <base-icon name="loading" width="35" height="35" />
                    <p id="cancel-label">Operazione in corso...</p>
                  </div>
                </template>
              </b-overlay>
            </b-card-text>
          </b-card>
        </b-form>
      </validation-observer>
    </div>
    <div class="mt-3">
      <div>
        <b-col class="mb-1 pr-0" align="right">
          <b-button
            class="btn-create"
            type="button"
            variant="primary"
            size="sm"
            @click="openAddRegistryModal"
            title="Associa anagrafiche al gruppo"
            ><b-icon icon="plus-circle" aria-hidden="true"></b-icon> Associa
            anagrafiche al gruppo</b-button
          >
        </b-col>
        <b-card header="Impostazioni Anagrafiche Associate" header-tag="header">
          <b-card-text>
            <!-- :filterOn="{ byRegistryGroup: { id: id } }" -->
            <table-registries
              :onlyActions="['infomodal']"
              noCustom
              hasChecks
              :fields="editFields"
              repository="registry"
              resource="registries"
              :filterName="filterName"
              :hasChecksPaginated="true"
              :ref="tableRef"
              :onPagination="{
                action: 'confirm',
                checkCondition: hasChanges,
                title: 'Conferma salvataggio',
                message: 'Stai per cambiare pagina. Salvare le modifiche?',
                yesCallback: saveOnPageChange,
                noCallback: onCancelConfirm,
                yesLabel: 'SALVA',
                yesVariant: 'lisaweb',
                noLabel: 'ANNULLA',
              }"
              noInnerWidth
              @interaction="onInteraction"
              @coordinator="onCoordinator"
              @communication="onCommunication"
            ></table-registries>
          </b-card-text>
          <!-- <b-overlay center :show="isUpdating" rounded="sm"> -->
          <!-- <div v-if="!isUpdating"> -->
          <b-button
            type="button"
            variant="lisaweb"
            size="sm"
            @click="onRemoveAndUpdate"
            title="Aggiorna impostazioni"
            >Aggiorna</b-button
          >
          <!-- </div> -->
          <!-- <template #overlay>
              <div class="text-center mt-1">
                <base-icon name="loading" width="35" height="35" />
                <p id="cancel-label2">Operazione in corso...</p>
              </div>
            </template>
          </b-overlay> -->
        </b-card>
      </div>
      <add-registry-modal :groupId="id" @save="onAdd"></add-registry-modal>
    </div>
    <b-button
      @click="$router.back()"
      variant="lisaweb"
      size="sm"
      class="mt-2 float-right"
      ><b-icon-chevron-double-left font-scale="0.9"></b-icon-chevron-double-left
      >Torna indietro</b-button
    >
  </div>
</template>

<script>
import FormMixin from "@/mixins/FormMixin";
import BaseIcon from "@/components/BaseIcon";
import BaseInput from "@/components/form/BaseInput";
import TableRegistries from "@/components/tables/Registries";
import ShortcutMixin from "@/mixins/ShortcutMixin";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import AddRegistryModal from "@/components/modals/addRegistryModal";
// import DetailTabAutofetchTableMixin from "@/mixins/DetailTabAutofetchTableMixin";
import ConfirmModalMixin from "@/mixins/ConfirmModalMixin";
import StorageGetterMixin from "@/mixins/StorageGetterMixin";
import { mapActions } from "vuex";
import { computed } from "vue";

export default {
  mixins: [FormMixin, ShortcutMixin, ConfirmModalMixin, StorageGetterMixin],
  data() {
    return {
      hasUserInteraction: false, // se l'utente seleziona/deseleziona checkbox e/o select
      isOnPageChange: false, // chiamo il metodo salvataggio da page change e non dal bottone Aggiorna
      filter: this.initFilter(),
      filterName: "filterRegistryEdit",
      isLoading: false,
      // isUpdating: false,
      tableRef: "registryTableEdit",
      fields: [
        {
          key: "check",
          label: "",
          sortable: false,
          thClass: "check",
          class: "check",
        },
        {
          key: "customer",
          label: "Nominativo / Ragione Sociale",
          sortable: false,
        },
      ],
      editFields: [
        {
          key: "check",
          label: "Rimuovi dal gruppo",
          sortable: false,
          thClass: "check",
          class: "check",
          thStyle: { width: "10px" },
        },
        {
          key: "customer",
          label: "Nominativo / Ragione Sociale",
          sortable: true,
          sortKey: "title",
          thStyle: { width: "200px" },
        },
        {
          key: "is_coordinator",
          label: "Capogruppo",
          sortable: false,
          tdClass: "is-coordinator",
          thStyle: { width: "100px" },
        },
        {
          key: "is_primary_group",
          label: "Gruppo Primario",
          sortable: false,
          thStyle: { width: "100px" },
        },
        {
          key: "communication_type",
          label: "Canale di Comunicazione",
          sortable: false,
          tdClass: "communication-type",
          thStyle: { width: "200px" },
        },
      ],
      repository: "registry_group",
      id: this.$route.params.id,
      form: {
        registry_group: {
          code: null,
          title: null,
        },
      },
      communication_types: [],
      communication_type: 5,
      coordinator: {},
    };
  },
  provide() {
    // use function syntax so that we can access `this`
    return {
      gId: computed(() => this.grpId),
      args: computed(() => {
        return {
          extra: {
            runBefore: () => {
              this.handleCoordinator(this.id);
            },
          },
        };
      }),
      communication_types: computed(() => this.communication_types),
      communication_type: computed(() => this.communication_type),
      coordinator: computed(() => this.coordinator),
    };
  },
  components: {
    BaseIcon,
    BaseInput,
    TableRegistries,
    AddRegistryModal,
  },
  methods: {
    handleCoordinator(gId) {
      // this.isLoading = true;
      return this.loadCoordinator(gId);
    },
    loadCoordinator(gId) {
      const Repo = RepositoryFactory.get("registry");
      let queryString = `byGroupCoordinator[id]=${gId}`;
      return Repo.index(queryString)
        .then((response) => {
          // set the coordinator
          this.$set(
            this,
            "coordinator",
            response.data.data.length ? response.data.data[0] : {}
          );
          this.setCommunicationTypes(gId);
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({ preset: "error", text: errMsg });
        });
    },
    setCommunicationTypes(gId) {
      const Repo = RepositoryFactory.get("registry_group");
      return Repo.pivot_form(gId, "registry")
        .then((response) => {
          console.log(response.data.fields.communication_type.options);
          // se non c'è il coordinator escludo this.communication_type (id = 4)
          this.communication_types =
            response.data.fields.communication_type.options.filter((o) =>
              !this.coordinator?.id ? o.value !== this.communication_type : true
            );
          // this.isLoading = false;
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({ preset: "error", text: errMsg });
        });
    },
    onCancelConfirm() {
      this.$showSnackbar({
        preset: "info",
        text: `Salvataggio annullato`,
      });
      this.hasUserInteraction = false;
      return true;
    },
    initFilter() {
      let init = {
        byRegistryGroup: { id: this.$route.params.id },
      };
      return init;
    },
    async changeCoordinator(
      registry_group_id,
      registry_id,
      coordinator_id,
      unselect = false
    ) {
      let tableRef = this.$refs[this.tableRef];
      // registry_id is the newly chosen coordinator
      // coordinator_id is the already exising

      // this.hasUserInteraction = true;
      // uncheck all 'Capogruppo' checkboxes for all registry_id(s) except the one related to the given registry_id
      if (this.$refs[this.tableRef] !== undefined) {
        // se non c'era il Capogruppo, devo salvare subito e ricaricare per avere il communicationype "Tramite Capogruppo"
        /* if (!coordinator_id) {
          this.onRemoveAndUpdate();
        } else {
          let items = this.$refs[this.tableRef].items;
          if (items.length) {
            for (const item of items) {
              const index = item.registry_groups.findIndex(
                (elem) => elem.id == registry_group_id
              );
              const pivot = item.registry_groups[index].pivot;
              //reset all but this registry_id
              if (pivot.registry_id != registry_id) {
                pivot.is_coordinator.value = "N"; // REVIEW coul be true|false, Y|N depending on backend inplementation
              } else {
                pivot.is_coordinator.value = "Y";
              }
            }
          }
          // if (registry_id === coordinator_id) {
          // se l'interazione è indiretta (clicco un registry e disabilito un altro registry), c'è già la chiamata in onInteraction
          // qui chiamo solo per interazione diretta
          this.onRemoveAndUpdate();
          // }
        } */
        let payload = {};
        // if (coordinator_id) {

        //   // pulisco il checkbox del precedente
        //   let items = this.$refs[this.tableRef].items;
        //   if (items.length) {
        //     for (const item of items) {
        //       const index = item.registry_groups.findIndex(
        //         (elem) => elem.id == registry_group_id
        //       );
        //       const pivot = item.registry_groups[index].pivot;
        //       //reset all but this registry_id
        //       if (pivot.registry_id != registry_id) {
        //         pivot.is_coordinator.value = "N"; // REVIEW coul be true|false, Y|N depending on backend inplementation
        //       } /* else {
        //         pivot.is_coordinator.value = "Y";
        //         payload[this.id] = {
        //           is_coordinator: pivot.is_coordinator.value,
        //           communication_type: pivot.communication_type.value,
        //         };
        //       } */
        //     }
        //   }
        // }
        payload[registry_group_id] = {
          is_coordinator: unselect ? "N" : "Y",
        };
        this.isLoading = true;
        // this.isUpdating = true;
        try {
          await this.storePivotDataRev(registry_id, payload);
          // await this.storePivotData(payload);
          // this.isUpdating = false;
          this.$showSnackbar({
            preset: "success",
            text: "Impostazioni aggiornate con successo",
          });
          tableRef.selectedRows = [];
          tableRef.checkAll = false;
          if (this.hasUserInteraction && this.isOnPageChange) {
            this.isLoading = false;
            this.hasUserInteraction = false;
            this.isOnPageChange = false;
          } else {
            let criteria = this.filter;
            let name = this.filterName;
            this.saveFilterByName({ name, criteria });
            tableRef.currentPage = 1;
            tableRef.fetch().then(() => {
              // recupero il capogruppo...
              // let gId = tableRef.getGroupId();
              // tableRef.handleCoordinator(gId).then(() => {
              this.isLoading = false;
              this.hasUserInteraction = false;
              // });
            });
          }
        } catch (error) {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
          this.restoreCoordinator(registry_group_id, coordinator_id);
          this.isLoading = false;
        }
        // this.onRemoveAndUpdate();
      }
    },
    restoreCoordinator(registry_group_id, coordinator_id) {
      if (this.$refs[this.tableRef] !== undefined) {
        let items = this.$refs[this.tableRef].items;
        if (items.length) {
          for (const item of items) {
            const index = item.registry_groups.findIndex(
              (elem) => elem.id == registry_group_id
            );
            const pivot = item.registry_groups[index].pivot;
            //reset all but this registry_id
            if (pivot.registry_id != coordinator_id) {
              pivot.is_coordinator.value = "N"; // REVIEW coul be true|false, Y|N depending on backend inplementation
            } /* else {
              pivot.is_coordinator.value = "Y";
            } */
          }
        }
      }
      // reset hasUserInteraction = false only if was not true already
      this.hasUserInteraction = this.hasUserInteraction || false;
    },
    onCoordinator(registry_group_id, registry_id, coordinator_id) {
      if (coordinator_id && registry_id != coordinator_id) {
        this.showConfirm({
          yesCallback: () => {
            this.changeCoordinator(
              registry_group_id,
              registry_id,
              coordinator_id
            );
          },
          noCallback: () => {
            this.restoreCoordinator(registry_group_id, coordinator_id);
          },
          title: "Conferma operazione",
          message: `Esiste già un capogruppo: sicuro di volerlo cambiare? ${
            this.hasUserInteraction
              ? "Ci sono anche alcune modifiche pendenti, per non perderle clicca 'MANTIENI' e poi 'AGGIORNA'"
              : ""
          }`,
          yesLabel: "CAMBIA",
          noLabel: "MANTIENI",
          // yesVariant:
          // noVariant:
          // headerBgVariant:
        });
      } else {
        this.changeCoordinator(registry_group_id, registry_id, coordinator_id);
      }
    },
    onCommunication() {
      this.hasUserInteraction = true;
    },
    onInteraction(registry_group_id, registry_id, coordinator_id) {
      if (!registry_group_id && !registry_id && !coordinator_id) {
        // evento generico (su tendina canali di comunicazione e/o su checkbox per eliminazione)
        this.hasUserInteraction = true;
      } else {
        // evento sul capogruppo
        if (registry_id != coordinator_id) {
          // sto "mantenendo" il capogruppo (non registro l'interazione, tutto torna come prima)
          this.hasUserInteraction = false;
        } else {
          // sto "rimuovendo" il capogruppo
          // this.hasUserInteraction = true;
          // salvo subito
          // this.onRemoveAndUpdate();
          this.changeCoordinator(
            registry_group_id,
            registry_id,
            coordinator_id,
            true
          );
        }
      }
    },
    saveOnPageChange() {
      this.isOnPageChange = true;
      return this.onRemoveAndUpdate();
    },
    async onRemoveAndUpdate() {
      let tableRef = this.$refs[this.tableRef];
      // let promises = [];
      if (tableRef !== undefined) {
        // FIX: 1 non servirà eliminarli chiamando appositamente removeFromGroup(...),
        // ma basterà chiamare la PUT senza passare nel payload gli id che si vuole eliminare
        // remove registries from group
        let registryIds = tableRef.selectedRows;
        /* if (registryIds.length) {
          promises.push(this.removeFromGroup(registryIds));
        } */
        // update registries pivot fields (only items not removed yet!!)
        let items = tableRef.items.filter(
          (item) => !registryIds.includes(item.id)
        );
        let payload = {};
        // col BE 3.0.27.3 del 05/08/2022 non devo più tener traccia di un eventuale coordinator...
        // let isThereAnyCoordinator = false;
        // let cId;
        /* if (items.length) {
          for (const item of items) {
            const index = item.registry_groups.findIndex(
              (elem) => elem.id == this.id
            );
            const pivot = item.registry_groups[index].pivot;
            payload = {};
            payload[this.id] = {
              is_coordinator: pivot.is_coordinator.value,
              communication_type: pivot.communication_type.value,
            };
            // // isThereAnyCoordinator =
            // //   isThereAnyCoordinator || pivot.is_coordinator.value === "Y";
            // if (pivot.is_coordinator.value === "Y") {
            //   cId = pivot.registry_id;
            //   isThereAnyCoordinator = true;
            // }
            promises.push(this.storePivotData(pivot.registry_id, payload));
          }
          // se tra questi registry c'è il coordinator ed è diverso dal precedessore, imposto a N quello del predecessore, se esiste.
          // if (
          //   isThereAnyCoordinator &&
          //   tableRef.coordinator &&
          //   tableRef.coordinator.id &&
          //   tableRef.coordinator.id != cId
          // ) {
          //   payload[tableRef.coordinator.id] = {
          //     is_coordinator: "N",
          //     communication_type: tableRef.coordinator.registry_groups.find(
          //       (e) => e.id == this.id
          //     ).pivot.communication_type.value,
          //   };
          // }
        } */
        // ... perciò posso fare 1 unica chiamata, con 1 unico payload (forse già prima avrei potuto farlo)
        // senza dover includere però dal payload il reset dell'ex capogruppo - sebbene qui sto salvando il resto!!
        if (items.length) {
          for (const item of items) {
            const index = item.registry_groups.findIndex(
              (elem) => elem.id == this.id
            );
            const pivot = item.registry_groups[index].pivot;
            payload[pivot.registry_id] = {
              // is_coordinator: pivot.is_coordinator.value,
              communication_type: pivot.communication_type.value,
            };
          }
        }
        this.isLoading = true;
        // this.isUpdating = true;
        let already_deleted = false;
        try {
          // await Promise.all(promises);
          // await this.storePivotData(payload);
          if (registryIds.length) {
            already_deleted = true;
            await this.removeFromGroup(registryIds);
          }
          if (items.length) {
            // se ho qualcosa da aggiornare....
            await this.updatePivotData(payload);
            if (!already_deleted) await this.removeFromGroup(registryIds);
            // this.isUpdating = false;
            this.$showSnackbar({
              preset: "success",
              text: "Impostazioni aggiornate con successo",
            });
          } /* else {
            // ci sono soltanto eliminazioni: chiamo la destroy
            await this.removeFromGroup(registryIds);
          } */
          tableRef.selectedRows = [];
          tableRef.checkAll = false;
          if (this.hasUserInteraction && this.isOnPageChange) {
            this.isLoading = false;
            this.hasUserInteraction = false;
            this.isOnPageChange = false;
          } else {
            let criteria = this.filter;
            let name = this.filterName;
            this.saveFilterByName({ name, criteria });
            tableRef.currentPage = 1;
            tableRef.fetch().then(() => {
              // recupero il capogruppo...
              // let gId = tableRef.getGroupId();
              // tableRef.handleCoordinator(gId).then(() => {
              this.isLoading = false;
              this.hasUserInteraction = false;
              // });
            });
          }
        } catch (error) {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
          this.isLoading = false;
        }
      }
    },
    // onUpdate() {
    //   // update registries pivot fields
    //   let tableRef = this.$refs[this.tableRef];
    //   this.isLoading = true;
    //   if (tableRef !== undefined) {
    //     let items = tableRef.items;
    //     let payload = {};
    //     if (items.length) {
    //       for (const item of items) {
    //         const index = item.registry_groups.findIndex(
    //           (elem) => elem.id == this.id
    //         );
    //         const pivot = item.registry_groups[index].pivot;
    //         payload[pivot.registry_id] = {
    //           is_coordinator: pivot.is_coordinator.value,
    //           communication_type: pivot.communication_type.value,
    //         };
    //       }
    //       return this.storePivotData(payload)
    //         .then(() => {
    //           this.$showSnackbar({
    //             preset: "success",
    //             text: "Impostazioni anagrafica salvate con successo",
    //           });
    //           // sto salvando dalla confirm al cambio pagina, non devo chiamare la fetch perché ci pensa l'handler...
    //            if (this.hasUserInteraction && this.isOnPageChange) {
    //             this.isLoading = false;
    //             this.hasUserInteraction = false;
    //             this.isOnPageChange = false;
    //           } else {
    //             let criteria = this.filter;
    //             let name = this.filterName;
    //             this.saveFilterByName({ name, criteria });
    //             tableRef.currentPage = 1;
    //             tableRef.fetch().then(() => {
    //               this.isLoading = false;
    //             });
    //           }
    //         })
    //         .catch((error) => {
    //           let errMsg = this.$getErrorMessage(error);
    //           this.$showSnackbar({
    //             preset: "error",
    //             text: `${errMsg}`,
    //           });
    //           this.isLoading = false;
    //           this.hasUserInteraction = false;
    //         });
    //     }
    //   }
    // },
    onSubmit() {
      this.isLoading = true;
      this.update(this.repository, this.id)
        .then(() => {
          this.hasUserInteraction = false;
          this.$showSnackbar({
            preset: "success",
            text: "Gruppo salvato con successo",
          });
          this.shortcut(
            "module.PFOLIO",
            null,
            "#RegistryGroups",
            "filterRegistryGroup"
          );
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onRemove() {
      let promises = [];
      // remove registries from group
      if (this.$refs[this.tableRef] !== undefined) {
        let registryIds = this.$refs[this.tableRef].selectedRows;
        if (registryIds.length) {
          promises.push(this.removeFromGroup(registryIds));
        }
      }
      if (!promises.length) {
        this.$showSnackbar({
          preset: "info",
          text: "Selezionare almeno una anagrafica",
        });
        return;
      }
      Promise.all(promises)
        .then(() => {
          this.$showSnackbar({
            preset: "success",
            text: `${
              promises.length === 1
                ? "Anagrafica rimossa"
                : "Anagrafiche rimosse"
            } dal gruppo con successo`,
          });
          this.$refs[this.tableRef].fetch();
          this.$refs[this.tableRef].selectedRows = [];
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onAdd(registryIds /* , modalItems */) {
      let tableRef = this.$refs[this.tableRef];
      this.addToGroup(registryIds /* , modalItems */)
        .then(() => {
          this.$showSnackbar({
            preset: "success",
            text: "Anagrafiche associate con successo",
          });
          // this.onSearch(this.filterName);
          let criteria = this.filter;
          let name = this.filterName;
          this.saveFilterByName({ name, criteria });
          tableRef.currentPage = 1;
          tableRef.fetch().then(() => {
            this.hasUserInteraction = false;
          });
          // .then(() => {
          //   this.$showSnackbar({
          //     preset: "success",
          //     text: "Anagrafiche associate con successo",
          //   });
          // })
          // .catch((error) => {
          //   let errMsg = this.$getErrorMessage(error);
          //   this.$showSnackbar({
          //     preset: "error",
          //     text: `${errMsg}`,
          //   });
          // });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
    addToGroup(registryIds /* , modalItems */) {
      const Repo = RepositoryFactory.get(this.repository);
      let obj = {};
      registryIds.forEach((r) => {
        obj[r] = {};
        /* // il BE richiede communication_type obbligatorio... quale scelgo?
        // prendo da attributes.CHAN degli items tornati dalla emit('save') della modale
        let registry = modalItems.find((e) => e.id === r);
        let ctMap = {
          0: "E-mail",
          1: "Posta",
          2: "SMS",
          3: "Telefono",
          4: "Tramite Capogruppo",
          5: "No Avviso",
        };
        let ct;
        if (registry?.attributables?.CHAN) {
          // recupero l'id relativo a CHAN (che è la stringa)
          ct = Object.keys(ctMap).find(
            (e) => ctMap[e] === registry.attributables.CHAN
          );
        } else {
          ct = 5;
        }
        obj[r] = { communication_type: ct }; */
      });
      return Repo.pivot_store(this.id, "registry", {
        registry: obj,
      });
    },
    removeFromGroup(registryIds) {
      const Repo = RepositoryFactory.get(this.repository);
      return Repo.pivot_destroy(this.id, "registry", {
        registry: registryIds,
      });
    },
    updatePivotData(payload) {
      const Repo = RepositoryFactory.get(this.repository);
      return Repo.pivot_update(this.id, "registry", {
        registry: payload,
      });
    },
    storePivotData(payload) {
      const Repo = RepositoryFactory.get(this.repository);
      return Repo.pivot_store(this.id, "registry", {
        registry: payload,
      });
    },
    storePivotDataRev(registryId, payload) {
      const Repo = RepositoryFactory.get("registry");
      return Repo.pivot_store(registryId, "registry_group", {
        registry_group: payload,
      });
    },
    openAddRegistryModal() {
      this.$bvModal.show("addRegistryModal");
    },
    onSearch(name) {
      // fetch registry data
      let criteria = this.filter;
      // let name = this.filterName;
      this.saveFilterByName({ name, criteria });
      this.$refs[this.tableRef]
        .fetch()
        .then(() => {
          this.removeFilterByName(name);
          this.removePaginationByName(name);
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({ preset: "error", text: errMsg });
        });
    },
    ...mapActions("filters", {
      removeFilterByName: "removeByName",
    }),
    ...mapActions("pagination", {
      removePaginationByName: "removeByName",
    }),
  },
  created() {
    this.isLoading = true;
    /*  */
    const path = "auth/";
    const resources = [];
    if (!this.hasLoadedResource("countries"))
      resources.push(
        this.$store
          .dispatch(`${path}countries`)
          .then(() => {})
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    if (!this.hasLoadedResource("councils"))
      resources.push(
        this.$store
          .dispatch(`${path}councils`)
          .then(() => {})
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    if (!this.hasLoadedResource("provinces"))
      resources.push(
        this.$store
          .dispatch(`${path}provinces`)
          .then(() => {})
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    if (resources.length) {
      this.getResources(resources)
        .then(() => {
          /* */
          this.fetchEditForm(this.repository, this.id).then(() => {
            this.isLoading = false;
            // fetch registry data
            this.onSearch(this.filterName);
          });
          /* */
        })
        .catch((error) => {
          console.log("One or more promises crashed... :|");
          this.onFailure(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      /* */
      this.fetchEditForm(this.repository, this.id).then(() => {
        this.isLoading = false;
        // fetch registry data
        this.onSearch(this.filterName);
      });
      /* */
    }
    /*  */
  },
  computed: {
    rep() {
      return this.repository;
    },
    ui() {
      return this.hasUserInteraction;
    },
    hasItems() {
      // per disabilitare il bottone Aggiorna: non funziona
      return this.$refs[this.tableRef] !== undefined &&
        !this.$refs[this.tableRef].isLoading
        ? this.$refs[this.tableRef].items.length
        : false;
    },
    hasChanges() {
      return this.hasUserInteraction;
    },
    grpId() {
      return this.filter.byRegistryGroup?.id
        ? !this.filter.byRegistryGroup.id.toString().startsWith("!")
          ? this.filter.byRegistryGroup.id
          : this.filter.byRegistryGroup.id.toString().slice(1)
        : this.filter.byRegistryGroup.id;
    },
  },
  // mounted() {
  //   // fecth data
  //   this.onSearch(this.filterName);
  //   this.$set(this.$refs[this.tableRef], "currentPage", 1);
  // },
};
</script>

<style lang="scss" scoped>
:deep(.table-responsive, .b-table-sticky-header) {
  /* overflow: inherit;  unset or auto value is not ok */
  overflow: inherit !important;
  max-height: 500px !important; /* prevent external container to scroll*/
  min-height: 100px !important;
}

:deep(.card-text .row .form-group) {
  margin-bottom: 0rem !important;
}

:deep(.check .form-group) {
  margin-bottom: unset !important;
}
:deep([data-type="data"] .card-body) {
  min-height: 55px;
}

:deep([data-type="data"] .b-overlay.position-absolute) {
  z-index: 2 !important;
}

:deep(
    table
      tbody
      td.is-coordinator:has(input[type="checkbox"]:checked)
      ~ td.communication-type
      .multiselect__content-wrapper
      li#null-5
  ) {
  display: none;
}
:deep(table tbody button[disabled="disabled"]) {
  display: none;
}
</style>
